<template>
  <div>
    <b-container fluid class="content">
      <span class="h1">{{ $t("Customer") }}</span>
      <b-row>
        <b-col>
          <b-card v-if="currpage == 'list'">
            <div class="p-0" v-if="customerlist">
              <div class="w-100 text-right pr-6" style="margin-bottom:10px">
                <el-button type="primary" class="big-button" icon="el-icon-circle-plus" size="large" @click="add_customer" v-acl:CustomerEdit.enable.show>
                  {{ $t("Add Customer") }}
                </el-button>
                <el-checkbox style="margin-left: 30px" v-model="includesInactiveVendors" :label="$t('Includes inactive')" size="large" />
              </div>
              <el-table
                class="table-responsive table"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="customerlist"
                :cell-style="{ padding: '0', height: '45px' }"
                :row-class-name="vendorRowClassName"
                @row-click="edit_customer"
              >
                <el-table-column label="Name" class="p-0" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Name") + "1" }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_CompanyName"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.CompanyName
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Name" class="p-0" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Name") + "2" }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_CompanyName2"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.OtherName
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Contact" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Contact") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_Contact"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.ContactLastName1
                        ? scope.row.ContactFirstName1 + " " + scope.row.ContactLastName1
                        : scope.row.ContactFirstName1
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="TelExt" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Tel") }}({{ $t("Ext") }})
                    </div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_TEL"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.ContactExt1
                        ? scope.row.TEL + " (" + scope.row.ContactExt1 + ")"
                        : scope.row.TEL
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Cel" prop="ContactCell1" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Cel") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_ContactCell1"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                </el-table-column>
                <el-table-column label="Email" prop="ContactEMail1" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Email") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_ContactEMail1"
                      @keyup.native="get_customerMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    <a :href="'mailto:' + scope.row.ContactEMail1">{{ scope.row.ContactEMail1 }}</a>
                  </template>
                </el-table-column>
                <el-table-column label="Type" prop="CompanyType" min-width="10">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Type") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="show_CompanyType"
                      placeholder=""
                    >
                      <el-option v-for="item in company_typelist_withEmpty"
                                :key="item.IDn"
                                :label="item.CompanyType"
                                :value="item.IDn"
                                @click.native="get_customerMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    <div class="pl-4">{{ getCompanyType(row.CompanyTypeIDn) }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="Sales" prop="SalesName" min-width="10">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Sales0") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="cur_SalesName"
                      placeholder=""
                    >
                      <el-option v-for="item in saleslist"
                                :key="item.IDn"
                                :label="item.UserName"
                                :value="item.IDn"
                                @click.native="get_customerMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    <div class="pl-4">{{ row.SalesName }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Status" prop="Status">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="cur_Status"
                      placeholder=""
                    >
                      <el-option v-for="item in status_options"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                                @click.native="get_customerMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.Status == 1 ? $t("Active") : $t("Inactive")
                    }}
                  </template>
                </el-table-column> -->
              </el-table>
              <el-row style="margin-top: 20px">
                <el-col :span="20">
                  <div>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="customer_total"
                      :per-page="per_page"
                    ></b-pagination>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div>
                    <span>
                      {{ $t("Max Rows per page") }}
                    </span>
                    <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        {{ per_page }}
                      </template>
                      <b-dropdown-item 
                        v-for="page_option in per_page_option"
                        :key="page_option"
                        :command="page_option"
                        @click="handleCommand">
                        {{ page_option }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="p-3" v-else>
              {{ $t("No Records") }}
            </div>
          </b-card>
          <b-card v-if="currpage == 'edit'">
            <el-row :gutter="10" style="">
              <el-col :span="12">
                <strong style="margin-right: 10px">{{ $t('Customer ID') + ":"}}</strong>
                <span>{{ cur_customer.Code ? cur_customer.Code : $t("To Be Assigned") }}</span>
              </el-col>
              <el-col :span="12" class="text-right">
                <strong style="margin-right: 40px">{{ $t('Credit Alert') + ":"}}</strong>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="cur_customer.Warning"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  style="padding-top: 6px"
                  >
                </b-form-checkbox>
                <strong style="margin-right: 40px">{{ $t('Status') + ":"}}</strong>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="cur_customer.Status"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  style="padding-top: 6px"
                > 
                </b-form-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="16">
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="'*' + $t('Name') + ' 1'">
                      <b-input
                        :placeholder="$t('Name') + ' 1'"
                        class="form-control"
                        v-model="cur_customer.CompanyName"
                        @input="validateInputField"
                        autocomplete="off"
                        :state="Name1_State"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter Customer's name
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="'*' + $t('Tel')">
                      <b-input
                        placeholder="Tel"
                        class="form-control"
                        v-model="cur_customer.TEL"
                        @input="validateInputField"
                        autocomplete="off"
                        :state="TEL_State"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter valid phone number
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Type')">
                      <InputAutocomplete2
                        :items="company_typelist"
                        :initval="Number(cur_customer.CompanyTypeIDn)"
                        :placeholder="$t('Type')"
                        :key_field="'IDn'"
                        :value_field="'CompanyType'"
                        @input="setinput_companytype"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('GST Number')">
                      <b-input
                        :placeholder="$t('GST Number')"
                        class="form-control"
                        v-model="cur_customer.GSTNo"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="'*' + $t('Name') + ' 2'">
                      <b-input
                        :placeholder="$t('Name') + ' 2'"
                        class="form-control"
                        v-model="cur_customer.OtherName"
                        @input="validateInputField"
                        autocomplete="off"
                        :state="Name2_State"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter customer's alternative name
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Fax')">
                      <b-input
                        :placeholder="$t('Fax')"
                        class="form-control"
                        v-model="cur_customer.FAX"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Website')">
                      <b-input
                        :placeholder="$t('Website')"
                        class="form-control"
                        v-model="cur_customer.WebSite"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('PST Number')">
                      <b-input
                        :placeholder="$t('PST Number')"
                        class="form-control"
                        v-model="cur_customer.PSTNo"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="$t('Price Group')">
                      <b-form-select v-model="cur_customer.PriceList" :options="PriceList"></b-form-select>
                    </base-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <base-input :label="$t('Remarks')">
                  <b-form-textarea
                    :placeholder="$t('Remarks')"
                    class="form-control"
                    v-model="cur_customer.Remarks"
                    autocomplete="off"
                    rows="5"
                    resize="none"
                  />
                </base-input>
              </el-col>
            </el-row>
          </b-card>
          <b-card v-if="currpage == 'edit'">
            <div :style="Address_State && Billing_State ? 'border: none' : 'border: 1px solid #fb6340; border-radius: 0.375rem' ">
              <AddressInput
                address_type="customer"
                :token="token"
                :user_id="user_id"
                :ReferenceIDn="Number(cur_customer.IDn)"
                @added_address_IDn="add_to_address_added"
                @deleted_address_IDn="remove_from_address_added"
                @cur_address_list="update_cur_address_list"
                @temp_new_address="add_to_new_customer_addresses"
                @temp_delete_address="remove_from_new_customer_addresses"
                @temp_update_address="update_new_customer_addresses"
                @temp_billing_address="set_billing_new_customer_addresses"
                @temp_shipping_address="set_shipping_new_customer_addresses"
              >
              </AddressInput>
              <span v-if="!Address_State" style="padding: 0.3rem; width: 100%; margin-top: 0.25rem; font-size: 80%; color:#fb6340"> {{ "Please provide an address" }} </span>
              <span v-else-if="!Billing_State" style="padding: 0.3rem; width: 100%; margin-top: 0.25rem; font-size: 80%; color:#fb6340"> {{ "Please select an address as Billing Address" }} </span>
            </div>
          </b-card>

          <el-collapse v-if="currpage == 'edit'" accordion>
            <el-collapse-item
              class="collapseheader"
              :title="$t('Contact')"
              name="1"
            >
              <fieldset>
                <legend style="margin-left: 15px; padding-top: 10px;">{{ $t("Contact") + " 1" }}</legend>
                <el-row :gutter="10" style="margin: 0 10px">
                  <el-col :span="4">
                    <base-input :label="$t('First Name')">
                      <b-input
                        :placeholder="$t('First Name')"
                        class="form-control"
                        v-model="cur_customer.ContactFirstName1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Last Name')">
                      <b-input
                        :placeholder="$t('Last Name')"
                        class="form-control"
                        v-model="cur_customer.ContactLastName1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Cel')">
                      <b-input
                        :placeholder="$t('Cel')"
                        class="form-control"
                        v-model="cur_customer.ContactCell1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Ext')">
                      <b-input
                        :placeholder="$t('Ext')"
                        class="form-control"
                        v-model="cur_customer.ContactExt1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Email')">
                      <b-input
                        :placeholder="$t('Email')"
                        class="form-control"
                        v-model="cur_customer.ContactEMail1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Position')">
                      <InputAutocomplete2
                        :items="positionlist"
                        :initval="Number(cur_customer.ContactPositionIDn1)"
                        :placeholder="$t('Position')"
                        :key_field="'IDn'"
                        :value_field="'PositionName'"
                        @input="setinput_contactposition1"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                </el-row>
              </fieldset>

              <fieldset>
                <legend style="margin-left: 15px; padding-top: 10px">{{ $t("Contact") + " 2" }}</legend>
                <el-row :gutter="10" style="margin: 0 10px; padding-bottom: 10px">
                  <el-col :span="4">
                    <base-input :label="$t('First Name')">
                      <b-input
                        :placeholder="$t('First Name')"
                        class="form-control"
                        v-model="cur_customer.ContactFirstName2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Last Name')">
                      <b-input
                        :placeholder="$t('Last Name')"
                        class="form-control"
                        v-model="cur_customer.ContactLastName2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Cel')">
                      <b-input
                        :placeholder="$t('Cel')"
                        class="form-control"
                        v-model="cur_customer.ContactCell2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Ext')">
                      <b-input
                        :placeholder="$t('Ext')"
                        class="form-control"
                        v-model="cur_customer.ContactExt2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Email')">
                      <b-input
                        :placeholder="$t('Email')"
                        class="form-control"
                        v-model="cur_customer.ContactEMail2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Position')">
                      <InputAutocomplete2
                        :items="positionlist"
                        :initval="Number(cur_customer.ContactPositionIDn2)"
                        :placeholder="$t('Position')"
                        :key_field="'IDn'"
                        :value_field="'PositionName'"
                        @input="setinput_contactposition2"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                </el-row>
              </fieldset>
            </el-collapse-item>

            <el-collapse-item
              class="collapseheader"
              :title="$t('Carrier')"
              name="2"
            >
              <el-row :gutter="10" style="padding: 10px">
                <el-col :span="6">
                  <base-input :label="$t('Carrier')">
                    <el-autocomplete
                      class="inline-input"
                      id="carrier_name_textbox"
                      v-model="cur_carrier.CarrierName"
                      value-key="CarrierName"
                      :fetch-suggestions="querySearch"
                      :placeholder="$t('Carrier')"
                      style="width: 100%"
                      @select="selectCarrier"
                    ></el-autocomplete>
                    <!-- @input="resetCarrier" -->
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Tel')">
                    <b-input
                      :placeholder="$t('Tel')"
                      class="form-control"
                      v-model="cur_carrier.CarrierTEL"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Fax')">
                    <b-input
                      :placeholder="$t('Fax')"
                      class="form-control"
                      v-model="cur_carrier.CarrierFAX"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Account Number')">
                    <b-input
                      :placeholder="$t('Account Number')"
                      class="form-control"
                      v-model="cur_carrier.CarrierAccountNo"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
              </el-row>
            </el-collapse-item>

            <el-collapse-item
              class="collapseheader"
              :title="$t('Extra')"
              name="3"
            >
              <el-row :gutter="10" style="padding: 10px">
                <el-col :span="4">
                  <base-input :label="$t('Payment Term')">
                    <el-autocomplete
                      v-model="cur_PayPeriod"
                      :fetch-suggestions="querySearch_paymentTerm"
                      class="inline-input"
                      value-key="PayPeriod"
                      :placeholder="$t('Payment Term')"
                      style="width: 100%"
                      @select="setinput_payperiod"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Credit Limit')">
                    <el-autocomplete
                      v-model="cur_CreditTerm"
                      :fetch-suggestions="querySearch_creditTerm"
                      class="inline-input"
                      value-key="CreditTerm"
                      :placeholder="$t('Credit Term')"
                      style="width: 100%"
                      @select="setinput_creditterm"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Classification')">
                    <el-autocomplete
                      v-model="cur_CompanyClass"
                      :fetch-suggestions="querySearch_classification"
                      class="inline-input"
                      value-key="Classification"
                      :placeholder="$t('Classification')"
                      style="width: 100%"
                      @select="setinput_companyclass"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Product Classification')">
                    <el-autocomplete
                      v-model="cur_ProductClass"
                      :fetch-suggestions="querySearch_productClassification"
                      class="inline-input"
                      value-key="Classification"
                      :placeholder="$t('Product Classification')"
                      style="width: 100%"
                      @select="setinput_productclass"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Product Category')">
                    <el-autocomplete
                      v-model="cur_ItemGrouping"
                      :fetch-suggestions="querySearch_productCategory"
                      class="inline-input"
                      value-key="ItemGrouping"
                      :placeholder="$t('Product Category')"
                      style="width: 100%"
                      @select="setinput_itemgrouping"
                    />
                  </base-input>
                </el-col>
              </el-row>
            </el-collapse-item>


            <el-collapse-item
              class="collapseheader"
              :title="$t('Files')"
              name="4"
              v-acl:FileUtilities.enable.show
            >
              <div>
                <el-row :gutter="10" style="padding: 10px">
                  <el-col :span="12">
                    <b-form-file
                      v-model="userfile"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <!-- :state="Boolean(userfile)" -->
                  </el-col>
                  <el-col :span="12" >
                    <el-button type="primary" @click="file_upload">
                      {{ $t("Upload File") }}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <el-table
                stripe
                class="table-responsive table "
                style="padding: 10px"
                :header-cell-style="{background: '#eef1f6'}"
                :data="cur_file_list"
              >
                <el-table-column
                  :label="$t('Name')"
                  prop="OrigName"
                  class="p-0"
                  min-width="100px"
                >
                </el-table-column>
                <el-table-column :label="$t('Date Modified')">
                  <template v-slot="{ row }">
                    {{ row.CreateDate.substring(0, 19) }}
                  </template>
                </el-table-column>
                <el-table-column label="">
                  <template v-slot="{ row }">
                    <el-tooltip
                      effect="dark"
                      content="Delete"
                      placement="top"
                      :openDelay="1000"
                    >
                      <el-button
                        type="danger"
                        class="button"
                        icon="el-icon-delete"
                        @click="file_delete(row, $event)"
                      >
                      </el-button>
                    </el-tooltip>

                    <el-tooltip
                      effect="dark"
                      content="Download"
                      placement="top"
                      :openDelay="1000"
                    >
                      <el-button
                        class="button"
                        icon="el-icon-download"
                        @click="file_download(row, $event)"
                      >
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </b-col>
      </b-row>
      <b-row class="mt-4" style="padding-top: 30px">
        <b-col v-if="currpage == 'edit'">
          <el-row :gutter="50">
            <el-col :span="12">
              <el-button type="primary" @click="cancel_edit" style="float: right">
                {{ $t("Back") }}
              </el-button>
            </el-col>
            <el-col :span="12" >
              <el-button type="primary" @click="validateForm">
                {{ $t("Submit Customer") }}
              </el-button>
            </el-col>
          </el-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
  Icon,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
} from "element-ui";

// import InputAutocomplete from "../InputAutocomplete";
import InputAutocomplete2 from "../InputAutocomplete2";
import AddressInput from "../AddressInput";
// import flatPicker from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    // flatPicker,
    // InputAutocomplete,
    InputAutocomplete2,
    AddressInput,
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL;
    },
  },
  data() {
    return {
      includesInactiveVendors: false,
      Name1_State: true,
      Name2_State: true,
      TEL_State: true,
      Address_State: true,
      Billing_State: true,
      status_options: [{
          value: "-1",
          label: " "
        },{
          value: "1",
          label: "Active"
        }, {
          value: "0",
          label: "Inactive"
      }],
      cur_CompanyName: "",
      cur_CompanyName2: "",
      cur_Contact: "",
      cur_TEL: "",
      cur_ContactCell1: "",
      cur_ContactEMail1: "",
      show_CompanyType: "",
      cur_SalesName: "",
      cur_Status: "1",    // Active
      cur_provincelist: [],
      address_added: [],

      currpage: "list",
      cur_opt: null,
      cur_customer: {},
      new_customer: {
        IDn: 0,
        CompanyName: "",
        OtherName: "",
        Status: 1,
        WebSite: "",
        TEL: "",
        FAX: "",
        CompanyTypeIDn: 0,
        PaymentTermIDn: 0,
        CreditTermIDn: 0,
        CompanyClassIDn: 0,
        ProductClassIDn: 0,
        ItemGroupingIDn: 0,
        PriceList: "A",
        CarrierIDn: 0,
        ContactFirstName1: "",
        ContactLastName1: "",
        ContactPositionIDn1: 0,
        ContactCell1: "",
        ContactExt1: "",
        ContactEMail1: "",
        ContactFirstName2: "",
        ContactLastName2: "",
        ContactPositionIDn2: 0,
        ContactCell2: "",
        ContactExt2: "",
        ContactEMail2: "",
        CreateDateTime: "",
        UpdateDateTime: "",
        Remarks: "",
        Warning: 0,
        PSTNo: "",
        GSTNo: "",
        SupportData: "",
        UserIDn: 0,
      },
      cur_CompanyType: "",
      cur_PayPeriod: "",
      cur_PriceList: "",
      cur_CreditTerm: "",
      cur_CompanyClass: "",
      cur_ProductClass: "",
      cur_ItemGrouping: "",
      cur_ContactPosition1: "",
      cur_ContactPosition2: "",

      carrier_list: [],
      carrier_name_list: [],
      carrier_account_no_list: [],
      cur_carrier_name: "",
      cur_carrier: {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
      },
      new_carrier: {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
        Order: 999,
      },
      cur_address_list: [],
      cur_address: {},
      new_address: {
        IDn: 0,
        Type: "",
        ReferenceIDn: 0,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 0,
        AddressProvince: "",
        CountryIDn: 0,
        AddressCountry: "",
        ZIP: "",
        Remarks: "",
        // BillingFlag: '',
        // ShippingFlag: '',
      },

      customerlist: [],
      // tax_typelist: [],
      company_typelist: [],
      company_typelist_withEmpty: [],
      positionlist: [],
      company_classlist: [],
      product_classlist: [],
      item_groupinglist: [],
      credit_termlist: [],
      pay_periodlist: [],
      saleslist: [],
      countrylist: [],
      provincelist: [],
      citylist: [],
      cur_file_list: [],

      userfile: null,

      delay_timer: null,

      current_page: 1,
      per_page: 20,
      customer_total: 0,

      token: "",
      user_id: 0,

      test: "",
      per_page_option: [5, 10, 20],
      PriceList: [
        { value: "A", text: "A" },
        { value: "B", text: "B" },
        { value: "C", text: "C" },
        { value: "D", text: "D" },
        { value: "E", text: "E" },
        { value: "F", text: "F" },
        { value: "G", text: "G" },
        { value: "H", text: "H" },
        { value: "I", text: "I" },
        { value: "J", text: "J" }
      ],
      new_customer_addresses: [],
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_customerMA_list();
    },
    includesInactiveVendors: function(newval, oldval) {
      newval ? this.cur_Status="" : this.cur_Status = "1";
      this.get_customerMA_list();
    },
  },
  methods: {
    hasUtilityAuth,
    selectCarrier(item) {
      this.cur_carrier.CarrierName = item.CarrierName;
      this.cur_carrier.CarrierTEL = item.CarrierTEL;
      this.cur_carrier.CarrierFAX = item.CarrierFAX;
      this.cur_carrier.CarrierAccountNo = item.CarrierAccountNo;
      this.cur_carrier.IDn = parseInt(item.IDn);
    },
    querySearch(queryString, cb) {
      var links = Object.values(this.carrier_list);
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.CarrierName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_paymentTerm(queryString, cb) {
      var links = Object.values(this.pay_periodlist);
      var results = queryString ? links.filter(this.createFilter_paymentTerm(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_paymentTerm(queryString) {
      return (link) => {
        return (link.PayPeriod.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_creditTerm(queryString, cb) {
      var links = Object.values(this.credit_termlist);
      var results = queryString ? links.filter(this.createFilter_creditTerm(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_creditTerm(queryString) {
      return (link) => {
        return (link.CreditTerm.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_classification(queryString, cb) {
      var links = Object.values(this.company_classlist);
      var results = queryString ? links.filter(this.createFilter_classification(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_classification(queryString) {
      return (link) => {
        return (link.Classification.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_productClassification(queryString, cb) {
      var links = Object.values(this.product_classlist);
      var results = queryString ? links.filter(this.createFilter_productClassification(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_productClassification(queryString) {
      return (link) => {
        return (link.Classification.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_productCategory(queryString, cb) {
      var links = Object.values(this.item_groupinglist);
      var results = queryString ? links.filter(this.createFilter_productCategory(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_productCategory(queryString) {
      return (link) => {
        return (link.ItemGrouping.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    resetCarrier(){
      this.cur_carrier = {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
      };
    },
    handleCommand(selected) {
      // console.log("click on item " + selected);
      this.per_page = Number(selected.target.innerText);
      // console.log(this.per_page);
      return this.get_customerMA_list();
    },
    extractURL(url) {
      if (axios.defaults.baseURL === undefined) {
        return url;
      } else {
        return axios.defaults.baseURL + "/Api/" + url;
      }
    },
    setinput_companytype(value) {
      this.cur_customer.CompanyTypeIDn = value.IDn;
      this.cur_CompanyType = value.CompanyType;
    },
    setinput_payperiod(value) {
      this.cur_customer.PaymentTermIDn = value.IDn;
      this.cur_PayPeriod = value.PayPeriod;
    },
    setinput_pricelist(value){
      this.cur_PriceList = value.value;
    },
    setinput_creditterm(value) {
      this.cur_customer.CreditTermIDn = value.IDn;
      this.cur_CreditTerm = value.CreditTerm;
    },
    setinput_companyclass(value) {
      this.cur_customer.CompanyClassIDn = value.IDn;
      this.cur_CompanyClass = value.Classification;
    },
    setinput_productclass(value) {
      this.cur_customer.ProductClassIDn = value.IDn;
      this.cur_ProductClass = value.Classification;
    },
    setinput_itemgrouping(value) {
      this.cur_customer.ItemGroupingIDn = value.IDn;
      this.cur_ItemGrouping = value.ItemGrouping;
    },
    setinput_contactposition1(value) {
      this.cur_customer.ContactPositionIDn1 = value.IDn;
      this.cur_ContactPosition1 = value.PositionName;
    },
    setinput_contactposition2(value) {
      this.cur_customer.ContactPositionIDn2 = value.IDn;
      this.cur_ContactPosition2 = value.PositionName;
    },
    setinput_city(city) {
      this.cur_address.AddressCity = city;
    },
    changed_province() {
      if (
        !this.cur_address.ProvinceIDn ||
        this.cur_address.ProvinceIDn == undefined ||
        this.cur_address.ProvinceIDn <= 0
      ) {
        return;
      }

      // this.cur_address.LocationProvince = this.provincelist[
      //   this.cur_store.ProvinceIDn
      // ].Province;
    },
    changed_country() {
      if (
        !this.cur_address.CountryIDn ||
        this.cur_address.CountryIDn == undefined ||
        this.cur_address.CountryIDn <= 0
      ) {
        this.cur_provincelist = this.provincelist;
        return;
      }

      // this.cur_address.LocationCountry = this.countrylist[
      //   this.cur_address.CountryIDn
      // ].Country;

      this.cur_provincelist = {};
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == this.cur_address.CountryIDn) {
          this.cur_provincelist[idx] = this.provincelist[idx];
        }
      }
    },
    address_combination(address) {
      let rt = "";
      rt += address.Street2
        ? address.Street1 + " " + address.Street2
        : address.Street1;
      rt += address.ZIP ? (rt ? ", " : "") + address.ZIP : "";
      rt += address.AddressCity ? (rt ? ", " : "") + address.AddressCity : "";
      rt += address.AddressProvince
        ? (rt ? ", " : "") + address.AddressProvince
        : "";
      rt += address.AddressCountry
        ? (rt ? ", " : "") + address.AddressCountry
        : "";
      return rt;
    },
    async add_customer() {
      this.currpage = "edit";
      this.cur_opt = "add";

      this.cur_customer = JSON.parse(JSON.stringify(this.new_customer));
      this.cur_carrier_name = "";
      this.resetCarrier();
      this.cur_customer.UserIDn = parseInt(this.user_id);
      this.address_added = [];
      this.new_customer_addresses = [];
      this.cur_PayPeriod = "";
      this.cur_CreditTerm = "";
      this.cur_CompanyClass = "";
      this.cur_ProductClass = "";
      this.cur_ItemGrouping = "";
      this.get_customer_extras();
      
    },
    async edit_customer(row, column, event) {
      if (!hasUtilityAuth('CustomerEdit')) return;
      if ( column && column.label == "Email") {
        // Skip some columns by Label
        return;
      }
      this.currpage = "edit";
      this.cur_opt = "edit";
      
      this.cur_customer = JSON.parse(JSON.stringify(row));
      this.cur_customer.UserIDn = parseInt(this.cur_customer.UserIDn);
      this.Name1_State = true;
      this.Name2_State = true;
      this.TEL_State = true;
      this.Address_State = true;
      this.Billing_State = true;
      if(row.CarrierIDn == 0){
        this.cur_carrier_name = "";
      } else {
        this.cur_carrier_name = this.cur_carrier.CarrierName;
      }
      this.address_added = [];
      await this.get_customer_extras();
    },
    async add_to_address_added(idn) {
      this.address_added.push(idn);
      this.validateInputField();
    },
    async remove_from_address_added(idn) {
      for (var i = 0; i < this.address_added.length; i++) {
        if (this.address_added[i] == idn) {
          this.address_added.splice(i, 1);
        }
      }
      this.validateInputField();
    },
    async update_cur_address_list(cur_address_list) {
      this.cur_address_list = cur_address_list;
      this.validateInputField();
    },
    async add_to_new_customer_addresses(address) {
      this.new_customer_addresses.push(address);
      this.validateInputField();
    },
    async remove_from_new_customer_addresses(address) {
      for (var i=0; i<this.new_customer_addresses.length; i++) {
        if (this.new_customer_addresses[i] == address) {
          this.new_customer_addresses.splice(i,1);
          break;
        }
      }
      this.validateInputField();
    },
    async update_new_customer_addresses(address) {
      for (var i=0; i<this.new_customer_addresses.length; i++) {
        if (this.new_customer_addresses[i].tempID == address.tempID) {
          this.new_customer_addresses[i] = address;
          break;
        }
      }
      this.validateInputField();
    },
    async set_billing_new_customer_addresses(address) {
      for (var i=0; i<this.new_customer_addresses.length; i++) {
        if (this.new_customer_addresses[i] == address) {
          this.new_customer_addresses.BillingFlag = 1;
        } else {
          this.new_customer_addresses.BillingFlag = 0;
        }
      }
      this.validateInputField();
    },
    async set_shipping_new_customer_addresses(address) {
      for (var i=0; i<this.new_customer_addresses.length; i++) {
        if (this.new_customer_addresses[i] == address) {
          this.new_customer_addresses.ShippingFlag = 1;
        } else {
          this.new_customer_addresses.ShippingFlag = 0;
        }
      }
      this.validateInputField();
    },
    async get_customer_extras() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      if (this.cur_customer.CarrierIDn != 'undefined' && this.cur_customer.CarrierIDn > 0) {
        bodyFormData.append("CarrierIDn", this.cur_customer.CarrierIDn);
      }
      // for(var pair of bodyFormData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]); 
      // }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/Customer/extras",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data.address_list;
              that.cur_file_list = rt.data.file_list;
              if (rt.data.carrier) {
                that.cur_carrier = rt.data.carrier;
              } else {
                for (let key in that.new_carrier) {
                  // console.log(key);
                  that.cur_carrier[key] = that.new_carrier[key];
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    does_carrier_exist(target){
      for (const key in this.carrier_list) {
        if(this.carrier_list[key].CarrierName == target){
          return true;
        }
      }
      return false;
    },
    validateInputField(){
      if(this.cur_customer.CompanyName != ''){
        this.Name1_State = true;
      }
      if(this.cur_customer.OtherName != ''){
        this.Name2_State = true;
      }
      if(this.cur_customer.TEL != ''){
        this.TEL_State = true;
      }
      if(this.cur_customer.IDn == "0") {
        if(this.new_customer_addresses.length > 0){
          this.Address_State = true;
          for(let i=0; i<this.new_customer_addresses.length; i++) {
            if(this.new_customer_addresses[i].BillingFlag == 1) {
              this.Billing_State = true;
            }
          }
        }
      } else {
        if(this.cur_address_list.length > 0){
          this.Address_State = true;
          for(let i=0; i<this.cur_address_list.length; i++) {
            if(this.cur_address_list[i].BillingFlag == 1) {
              this.Billing_State = true;
            }
          }
        }
      }
    },
    phoneNumberValidation(phoneNumber){
      var regex = /^[\d.\-()]+$/;
      if(phoneNumber.match(regex)){
        return true;
      } else {
        return false;
      }
    },
    validateForm(){
      let allPassed = true;

      if(this.cur_customer.CompanyName == ''){
        this.Name1_State = false;
        allPassed = false;
      } else {
        this.Name1_State = true;
      }
      if(this.cur_customer.OtherName == ''){
        this.Name2_State = false;
        allPassed = false;
      } else {
        this.Name2_State = true;
      }
      if(this.cur_customer.TEL == ''){
        this.TEL_State = false;
        allPassed = false;
      } else {
        if(!this.phoneNumberValidation(this.cur_customer.TEL)){
          this.TEL_State = false;
          allPassed = false;
        } else {
          this.TEL_State = true;
        }
      }
      if(this.cur_customer.IDn == "0") {
        if(this.new_customer_addresses.length == 0){
          this.Address_State = false;
          this.Billing_State = false;
          allPassed = false;
        } else {
          this.Address_State = true;
          for(let i=0; i<this.new_customer_addresses.length; i++){
            if(this.new_customer_addresses[i].BillingFlag == 1){
              this.Billing_State = true;
              allPassed = true;
              break;
            }
            this.Billing_State = false;
            allPassed = false;
          }
        }
      } else {
        if(this.cur_address_list.length == 0){
          this.Address_State = false;
          this.Billing_State = false;
          allPassed = false;
        } else {
          this.Address_State = true;
          for(let i=0; i<this.cur_address_list.length; i++){
            if(this.cur_address_list[i].BillingFlag == 1){
              this.Billing_State = true;
              allPassed = true;
              break;
            }
            this.Billing_State = false;
            allPassed = false;
          }
        }
      }

      if(allPassed){
        this.submit_customer();
      }
    },
    async submit_customer() {
      // if (!this.cur_customer.CompanyName.trim()) {
      //   alert("Name1 can't be empty");
      //   return;
      // }
      // if (!this.cur_customer.OtherName.trim()) {
      //   alert("Name2 can't be empty");
      //   return;
      // }
      // if (!this.cur_customer.TEL.trim()) {
      //   alert("Tel can't be empty");
      //   return;
      // }

      // var re = /^[\d.\-()]+$/;
      // var match_result = this.cur_customer.TEL.match(re);
      // if (match_result == null) {
      //   alert("Tel can only accept number, dot, dash, parentheses");
      //   return;
      // }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("opt", this.cur_opt);
      if (this.cur_opt == "add") {
        var file_added = "";
        for (var i = 0; i < this.cur_file_list.length; i++) {
          file_added += this.cur_file_list[i].IDn + ",";
        }
        if (file_added != "") {
          bodyFormData.append("file_added", file_added.slice(0, -1));
        }
        bodyFormData.append("new_addresses", JSON.stringify(this.new_customer_addresses));
      }
      this.address_added = [];
      for (var key in this.cur_customer) {
        bodyFormData.append(key, this.cur_customer[key]);
      }

      if(this.does_carrier_exist(this.cur_carrier.CarrierName)){
        bodyFormData.append("cur_CarrierIDn", this.cur_carrier.IDn);
      } else {
        this.cur_carrier.CarrierName = document.getElementById("carrier_name_textbox").value;
        bodyFormData.append("cur_CarrierIDn", 0);
        bodyFormData.append("cur_Carrier", JSON.stringify(this.cur_carrier));
      }
      bodyFormData.append("CompanyType", this.cur_CompanyType);
      bodyFormData.append("PayPeriod", this.cur_PayPeriod);
      // bodyFormData.append("PriceList", this.cur_PriceList);
      bodyFormData.append("CreditTerm", this.cur_CreditTerm);
      bodyFormData.append("CompanyClass", this.cur_CompanyClass);
      bodyFormData.append("ContactPosition1", this.cur_ContactPosition1);
      bodyFormData.append("ContactPosition2", this.cur_ContactPosition2);
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0]+ '    ' + pair[1]); 
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/Customer/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.currpage = "list";
              that.cur_opt = "list";
              that.get_customerMA_list();
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    cancel_edit() {
      this.currpage = "list";
    },
    vendorRowClassName({row, rowIndex}) {
      if (row.Status != 1) {
        return 'inactive-vendor';
      }
      return '';
    },
    modifyCompanyTypeList() {
      if(this.company_typelist_withEmpty.length == 0) {
        this.company_typelist_withEmpty[0] = {"IDn": 0, "CompanyType": " "};
        // Object.entries(this.company_typelist).forEach(entry => {
        //   const [key, value] = entry;
        //   this.company_typelist_withEmpty.push({"IDn": Number(value['IDn']), "CompanyType": value['CompanyType']});
        // });
        for(let i=0; i<this.company_typelist.length; i++) {
          this.company_typelist_withEmpty.push({"IDn": Number(this.company_typelist[i].IDn), "CompanyType": this.company_typelist[i].CompanyType});
        }
      }
    },
    getCompanyType(idn) {
      for(let i=0; i<this.company_typelist.length; i++) {
        if(idn == parseInt(this.company_typelist[i].IDn)) {
          return this.company_typelist[i].CompanyType;
        }
      }
      return "";
    },
    start_new_address(event) {
      if (event) {
        event.preventDefault();
      }
      // console.log("this.cur_address['ZIP']", this.cur_address['ZIP']);
      for (let key in this.new_address) {
        this.cur_address[key] = this.new_address[key];
      }
      // console.log("this.cur_address['ZIP']", this.cur_address['ZIP']);
    },
    async set_billing_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      bodyFormData.append("Type", "Customer");
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_billing",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async set_shipping_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      bodyFormData.append("Type", "Customer");
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_shipping",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async submit_address(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("Type", "Customer");
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      for (var key in this.cur_address) {
        if (this.cur_address[key]) {
          bodyFormData.append(key, this.cur_address[key]);
        } else if (key == "IsBilling") {
          bodyFormData.append("BillingFlag", 1);
        } else if (key == "IsShipping") {
          bodyFormData.append("ShippingFlag", 1);
        }
      }
      if (bodyFormData.get("BillingFlag")) {
        bodyFormData.append("BillingFlag", 0);
      }
      if (bodyFormData.get("ShippingFlag")) {
        bodyFormData.append("ShippingFlag", 0);
      }

      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    delete_address(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Type", "Customer");

      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    addreess_row_clicked_event(row, event, column) {
      // console.log("addreess_row_clicked_event", row, event, column);
    },
    get_customerMA_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_customerMA_list();
      }, 100);
    },
    async get_customerMA_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_CompanyName) {
        bodyFormData.append("CompanyName", this.cur_CompanyName);
      }
      if (this.cur_CompanyName2) {
        bodyFormData.append("OtherName", this.cur_CompanyName2);
      }
      if (this.show_CompanyType) {
        bodyFormData.append("CompanyTypeIDn", this.show_CompanyType);
      }
      if (this.cur_Contact) {
        bodyFormData.append("Name", this.cur_Contact);
      }
      if (this.cur_TEL) {
        bodyFormData.append("TEL", this.cur_TEL);
      }
      if (this.cur_ContactCell1) {
        bodyFormData.append("ContactCell1", this.cur_ContactCell1);
      }
      if (this.cur_ContactEMail1) {
        bodyFormData.append("ContactEMail1", this.cur_ContactEMail1);
      }
      if (this.cur_SalesName) {
        bodyFormData.append("UserIDn", this.cur_SalesName);
      }
      if (this.cur_Status) {
        bodyFormData.append("Status", this.cur_Status);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/Customer",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.customerlist = rt.data.customers;
              that.customer_total = rt.data.customer_total;
              that.company_typelist = rt.data.company_types;
              that.modifyCompanyTypeList();
              that.positionlist = rt.data.positions;
              that.company_classlist = rt.data.company_classes;
              that.product_classlist = rt.data.product_classes;
              that.item_groupinglist = rt.data.item_groupings;
              that.pay_periodlist = rt.data.pay_periods;
              that.credit_termlist = rt.data.credit_terms;
              that.saleslist = rt.data.sales;
              that.saleslist[0] = {"IDn": 0, "UserName": " "};
              that.carrier_list = rt.data.carriers;
              that.carrier_name_list = rt.data.carrier_names;
              that.carrier_account_no_list = rt.data.carrier_account_nos;
              that.countrylist = rt.data.countries;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    
    // select_carrier_idn() {
    //   if (this.cur_carrier.IDn) {
    //     this.cur_carrier = this.carrier_list[this.cur_carrier.IDn];
    //   }
    //   // else {
    //   //   for (let key in this.new_carrier) {
    //   //     this.cur_carrier[key] = this.new_carrier[key];
    //   //   }
    //   // }
    // },
    // select_carrier_name() {
    //   if (this.cur_carrier.CarrierName) {
    //     var tmp = {};
    //     var c = this.carrier_name_list[this.cur_carrier.CarrierName];
    //     for (let key in c) {
    //       tmp[key] = c[key];
    //     }
    //     this.cur_carrier = tmp;
    //   }
    // },
    // select_carrier_acc_no() {
    //   if (this.cur_carrier.CarrierAccountNo) {
    //     var tmp = {};
    //     var c = this.carrier_account_no_list[this.cur_carrier.CarrierAccountNo];
    //     for (let key in c) {
    //       tmp[key] = c[key];
    //     }
    //     this.cur_carrier = tmp;
    //   }
    // },

    async file_upload() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("RefType", "customer");
      bodyFormData.append("ReferenceIDn", this.cur_customer.IDn);
      bodyFormData.append("userfile", this.userfile);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/File_upload/upload",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.userfile = null;
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_file_list.push(rt.data);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async file_delete(file, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", file.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/File_upload/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.userfile = null;
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_file_list = rt.data;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    
    async file_download(file, event) {
      // Solution 1
      // Disadvantage: Explorer will display files it can display instead of downloading.
      // let url = this.extractURL(file.FilePath);
      // window.open(url, "_blank");

      // Solution 2
      var url = this.apiBaseUrl + "/Api/Web/File_upload/download?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&IDn=" + file.IDn;
      window.open(url, "_blank");
    },

    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_customerMA_list();
    },

    format_date(date) {
      // function join(t, a, s) {
      //   function format(m) {
      //     let f = Fnew Intl.DateTimeFormat('en', m);
      //     return f.format(t);
      //   }
      //   return a.map(format).join(s);
      // }
      // let a1 = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      // let s1 = join(date, a1, '-');
      // let a2 = [{hour: '2-digit', hour12: false}, {minute: '2-digit'}, {second: '2-digit'}];
      // let s2 = join(date, a2, ':');
      // return s1 + ' ' + s2;
      let p = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
        .formatToParts(date)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    formatDate(timestamp) {
      var utcTime = new Date(timestamp * 1000);
      // const d = new Date();
      // const localOffset = d.getTimezoneOffset() * 60 * 1000;
      // const localTime = utcTime - localOffset;
      return this.format_date(utcTime);
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table th {
  padding: 0rem;
}
.card .table td {
  padding: 0rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
  
}
/* .el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
} */
.form-group {
  margin-bottom: 0.5rem;
}

.command_button {
  font-weight: 700;
  border-width: 2px;
}

.green_button {
  border-color: green;
  color: green;
}
.green_button:focus {
  border-color: green;
  color: green;
  background-color: #dafada;
}
.green_button:hover {
  border-color: green;
  color: green;
  background-color: #9ef59e;
}
</style>
<style lang="scss" scoped>
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #b3e5fc;
}
/deep/ .inactive-vendor {
  background-color: antiquewhite;
}
/deep/ .big-button {
  font-size: 1rem;
  font-weight: 800;
}
/deep/ .table-header-input {
  height: 28px; 
}
</style>