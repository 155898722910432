<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <!-- row 1 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Report Type')">
                      <select class="form-control" v-model="report_type" @change="report_type_changed">
                        <option
                          v-for="(type, idx) in report_type_list"
                          :key="idx"
                          :value="type.IDn"
                        >
                          {{ type.Name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Date Type')">
                      <select class="form-control" v-model="date_type">
                        <option
                          v-for="(type, idx) in date_type_list"
                          :key="idx"
                          :value="type.IDn"
                        >
                          {{ type.Name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='from_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='to_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                </b-row>
                <!-- row 2 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id">
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Customer')">
                      <select class="form-control" v-model="cur_customer">
                        <option></option>
                        <option
                          v-for="(customer, idx) in customer_list"
                          :key="idx"
                          :value="customer.IDn"
                        >
                          {{ customer.CompanyName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <select class="form-control" v-model="cur_department_idn" @change="department_changed">
                        <option></option>
                        <option
                          v-for="(dpt, idx) in departmentlist"
                          :key="idx"
                          :value="dpt.IDn"
                        >
                          {{ dpt.PLU }}-{{ dpt.Name1 }}-{{ dpt.Name2 }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Category')">
                      <select class="form-control" v-model="cur_category_idn">
                        <option></option>
                        <option
                          v-for="(ct, idx) in categorylist"
                          :key="idx"
                          :value="ct.IDn"
                        >
                          {{ ct.PLU }}-{{ ct.Name1 }}-{{ ct.Name2 }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <!-- row 3 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('BySales')">
                      <select class="form-control" v-model="cur_sales_idn">
                        <option value=""></option>
                        <option
                          v-for="sales in sales_list"
                          :key="sales.IDn"
                          :value="sales.IDn"
                        >
                          {{ sales.UserName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Product')">
                      <SearchAutocomplete
                        :url="apiBaseUrl + '/Web/product/search_allforlist'"
                        whereFrom="PO"
                        id="list_search_product"
                        @input="set_cur_product"
                        @keyword="reset_cur_product"
                      ></SearchAutocomplete>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Summary Only')">
                      <b-form-checkbox
                        class="form-checkbox"
                        v-model="summary_only"
                        :value="true"
                        :unchecked-value="false"
                        switch>
                      </b-form-checkbox>
                    </base-input>
                  </b-col>
                  <b-col>
                    <div style="margin-top: 20px">
                      <b-button variant="primary" @click="form_search">
                        {{ $t("Search") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
          <div>
            <div v-if="list_data && list_data.length > 0">
              <!-- by category or product -->
              <div v-if="report_type==1 || report_type==2" v-bind:style="{ width: report_width }">
                <div v-bind:style="{ width: title_width + 'px' }">
                  <b-row>
                    <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                      <xlsx-workbook @change="change_for_report" @created="created_for_report">
                        <xlsx-sheet
                          :collection="sheet.data"
                          v-for="sheet in export_sheets_for_report"
                          :key="sheet.name"
                          :sheet-name="sheet.name"
                        />
                        <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                          <template #default="{ download }">
                            <b-button variant="primary" @click="export_for_edit(download)">
                              {{ $t("Export Excel") }}
                            </b-button>
                          </template>
                        </xlsx-download>
                      </xlsx-workbook>
                    </b-col>
                    <!-- <b-col class="text-center" lg="2">
                      <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                    </b-col> -->
                  </b-row>
                  <!-- title -->
                  <b-row style="margin-bottom: 10px">
                    <b-col><div v-bind:style="titleStyleObject">{{ saved_report_title }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject2"> - {{ saved_report_subtitle }}</div></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject3">Store:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Department:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Category:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">From:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">To:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Print Date:</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_store_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_department_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_category_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_from_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_to_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_datestr }}</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                </div>
                <div v-bind:style="{ width: table_width, 'margin-top': '5px' }">
                  <!-- head -->
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col><div v-bind:style="headerStyleObject">LOCATION</div></b-col>
                    <b-col :key='"col_" + idx' v-for='(col, idx) in list_column'>
                      <div v-bind:style="headerStyleObject">{{ col.LocationName }}</div>
                    </b-col>
                  </b-row>
                  <!-- body -->
                  <b-row :key='"depart_" + key1' v-for='(depart, key1) in list_data'>
                    <b-col>
                      <b-row>
                        <b-col>
                          <div v-bind:style="level1StyleObject">{{ depart.PLU + '-' + depart.Name1 + (depart.Name2?('-' + depart.Name2):'') }}</div>
                        </b-col>
                      </b-row>
                      <b-row :key='"cate_" + cate_key' v-for='(cate, cate_key) in depart.categorylist'>
                        <b-col>
                          <b-row>
                            <b-col>
                              <div v-bind:style="level2StyleObject">{{ cate.PLU + '-' + cate.Name1 + (cate.Name2?('-' + cate.Name2):'') }}</div>
                            </b-col>
                          </b-row>
                          <b-row v-if="report_type==1">
                            <b-col>
                              <b-row><b-col><div v-bind:style="leafStyleObject">Sold Qty</div></b-col></b-row>
                              <b-row><b-col><div v-bind:style="leafStyleObject">Total Sales($)</div></b-col></b-row>
                              <b-row><b-col><div v-bind:style="leafStyleObject">Total Profit($)</div></b-col></b-row>
                              <b-row><b-col><div v-bind:style="leafStyleObject">Profit Margin(%)</div></b-col></b-row>
                            </b-col>
                            <b-col :key='"store_" + store_key' v-for='(store, store_key) in cate.stores'>
                              <div v-if="store && store.Qty != undefined">
                                <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Qty).toFixed(2) }}</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Sales).toFixed(2) }}</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Sales - store.Cost).toFixed(2) }}</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">{{ (100 * (store.Sales - store.Cost) / store.Cost).toFixed(2) }}</div></b-col></b-row>
                              </div>
                              <div v-else>
                                <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row v-if="report_type==2">
                            <b-col>
                              <b-row :key='"product_" + pro_key' v-for='(product, pro_key) in cate.products'>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <div v-bind:style="level3StyleObject">{{ product.PLU + '-' + product.Name1 + (product.Name2?('-' + product.Name2):'') }}</div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row><b-col><div v-bind:style="leafStyleObject">Sold Qty</div></b-col></b-row>
                                      <b-row><b-col><div v-bind:style="leafStyleObject">Average Cost($)</div></b-col></b-row>
                                      <b-row><b-col><div v-bind:style="leafStyleObject">Total Sales($)</div></b-col></b-row>
                                      <b-row><b-col><div v-bind:style="leafStyleObject">Total Profit($)</div></b-col></b-row>
                                      <b-row><b-col><div v-bind:style="leafStyleObject">Profit Margin(%)</div></b-col></b-row>
                                    </b-col>
                                    <b-col :key='"store_" + store_key' v-for='(store, store_key) in product.stores'>
                                      <div v-if="store && store.Qty != undefined">
                                        <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Qty).toFixed(2) }}</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Cost / store.Qty).toFixed(2) }}</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Sales).toFixed(2) }}</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">{{ parseFloat(store.Sales - store.Cost).toFixed(2) }}</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">{{ (100 * (store.Sales - store.Cost) / store.Cost).toFixed(2) }}</div></b-col></b-row>
                                      </div>
                                      <div v-else>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                        <b-row><b-col><div v-bind:style="generalStyleObject">0.00</div></b-col></b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row> 
                  <!-- total -->
                  <b-row>
                    <b-col>
                      <hr style="border: 1px solid black;">
                    </b-col>
                  </b-row>  
                  <b-row>
                    <b-col><div v-bind:style="headerStyleObject">Total</div></b-col>
                    <b-col :key='"col_" + idx' v-for='(col, idx) in list_column'>
                      <div v-bind:style="headerStyleObject">{{ parseFloat(col.Sales).toFixed(2) }}</div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- by customer -->
              <div v-if="report_type==3">
                <div v-bind:style="{ width: title_width + 'px' }">
                  <b-row>
                    <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                      <xlsx-workbook @change="change_for_report" @created="created_for_report">
                        <xlsx-sheet
                          :collection="sheet.data"
                          v-for="sheet in export_sheets_for_report"
                          :key="sheet.name"
                          :sheet-name="sheet.name"
                        />
                        <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                          <template #default="{ download }">
                            <b-button variant="primary" @click="export_for_edit(download)">
                              {{ $t("Export Excel") }}
                            </b-button>
                          </template>
                        </xlsx-download>
                      </xlsx-workbook>
                    </b-col>
                    <!-- <b-col class="text-center" lg="2">
                      <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                    </b-col> -->
                  </b-row>
                  <!-- title -->
                  <b-row style="margin-bottom: 10px">
                    <b-col><div v-bind:style="titleStyleObject">{{ saved_report_title }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject2"> - {{ saved_report_subtitle }}</div></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject3">Store:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Customer:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">From:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">To:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Print Date:</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_store_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_customer_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_from_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_to_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_datestr }}</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                </div>
                <div v-bind:style="{ 'margin-top': '5px' }">
                  <!-- head -->
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col><div v-bind:style="headerStyleObjectLong">Invoice Date</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Invoice#</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">SubTotal</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Taxes</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Shipping</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Other</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Total</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Payment</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Balance</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">By Sales</div></b-col>
                  </b-row>
                  <!-- body -->
                  <b-row :key='"customer_" + key1' v-for='(customer, key1) in list_data'>
                    <b-col>
                      <b-row>
                        <b-col>
                          <div v-bind:style="level1StyleObject">{{ 'Customer: ' + customer.CompanyName + (customer.OtherName?(' / ' + customer.OtherName):'') }}</div>
                        </b-col>
                      </b-row>
                      <b-row :key='"so_" + so_key' v-for='(so, so_key) in customer.SOS'>
                        <b-col><div v-bind:style="generalStyleObjectLong">{{ so.SOEndDate ? (so.SOEndDate.length > 10 ? so.SOEndDate.substr(0, 10) : so.SOEndDate) : "" }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ so.InvoiceNo }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.SubTotal).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.TotalTax).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.ShipFee).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.OtherFees).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.TotalAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.PaidAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.Balance).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ so.OrderByName }}</div></b-col>
                      </b-row>
                      <!-- total -->
                      <b-row>
                        <b-col>
                          <hr style="margin:0;border-top: 1px solid black;">
                        </b-col>
                      </b-row>  
                      <b-row>
                        <b-col><div v-bind:style="headerStyleObjectLong">Total:</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject"></div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.SubTotal).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.TotalTax).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.ShipFee).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.OtherFees).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.TotalAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.PaidAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(customer.Total.Balance).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="headerStyleObject"></div></b-col>
                      </b-row>
                    </b-col>
                  </b-row> 
                </div>
              </div>
              <!-- by amount -->
              <div v-if="report_type==4">
                <div v-bind:style="{ width: title_width + 'px' }">
                  <b-row>
                    <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                      <xlsx-workbook @change="change_for_report" @created="created_for_report">
                        <xlsx-sheet
                          :collection="sheet.data"
                          v-for="sheet in export_sheets_for_report"
                          :key="sheet.name"
                          :sheet-name="sheet.name"
                        />
                        <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                          <template #default="{ download }">
                            <b-button variant="primary" @click="export_for_edit(download)">
                              {{ $t("Export Excel") }}
                            </b-button>
                          </template>
                        </xlsx-download>
                      </xlsx-workbook>
                    </b-col>
                    <!-- <b-col class="text-center" lg="2">
                      <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                    </b-col> -->
                  </b-row>
                  <!-- title -->
                  <b-row style="margin-bottom: 10px">
                    <b-col><div v-bind:style="titleStyleObject">{{ saved_report_title }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject2"> - {{ saved_report_subtitle }}</div></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject3">Store:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Customer:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">From:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">To:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Print Date:</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_store_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_customer_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_from_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_to_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_datestr }}</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                </div>
                <div v-bind:style="{ 'margin-top': '5px' }">
                  <!-- head -->
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col><div v-bind:style="headerStyleObjectLong">Start Date</div></b-col>
                    <b-col><div v-bind:style="headerStyleObjectLong2">Customer</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">SubTotal</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Total</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Count</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Payment</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Balance</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">By Sales</div></b-col>
                  </b-row>
                  <!-- body -->
                  <b-row :key='"so_" + key1' v-for='(so, key1) in list_data'>
                    <b-col><div v-bind:style="generalStyleObjectLong">{{ so.StartDate ? (so.StartDate.length > 10 ? so.StartDate.substr(0, 10) : so.StartDate) : "" }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObjectLong2">{{ so.CompanyName + (so.OtherName?(' / ' + so.OtherName):'') }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.SubTotal).toFixed(2) }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.TotalAmount).toFixed(2) }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ so.Count }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.PaidAmount).toFixed(2) }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.Balance).toFixed(2) }}</div></b-col>
                    <b-col><div v-bind:style="generalStyleObject">{{ so.OrderByName }}</div></b-col>
                  </b-row>
                </div>
              </div>
              <!-- Sales by Customer Product Purchase -->
              <div v-if="report_type==5" v-bind:style="{ width: '1200px' }">
                <div>
                  <b-row>
                    <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                      <xlsx-workbook @change="change_for_report" @created="created_for_report">
                        <xlsx-sheet
                          :collection="sheet.data"
                          v-for="sheet in export_sheets_for_report"
                          :key="sheet.name"
                          :sheet-name="sheet.name"
                        />
                        <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                          <template #default="{ download }">
                            <b-button variant="primary" @click="export_for_edit(download)">
                              {{ $t("Export Excel") }}
                            </b-button>
                          </template>
                        </xlsx-download>
                      </xlsx-workbook>
                    </b-col>
                    <!-- <b-col class="text-center" lg="2">
                      <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                    </b-col> -->
                  </b-row>
                  <!-- title -->
                  <b-row style="margin-bottom: 10px">
                    <b-col cols="2"><div v-bind:style="titleStyleObject">{{ saved_report_title }}</div></b-col>
                    <b-col cols="6"><div v-bind:style="titleStyleObject2"> - {{ saved_report_subtitle }}</div></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject3">Store:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Customer:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Department:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Category:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">From:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">To:</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject3">Print Date:</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                  <b-row>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_store_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_customer_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_department_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_category_name }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_from_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_to_date }}</div></b-col>
                    <b-col><div v-bind:style="titleStyleObject4">{{ saved_datestr }}</div></b-col>
                  </b-row>
                  <hr style="margin:0">
                </div>
                <div v-bind:style="{ 'margin-top': '5px' }">
                  <!-- head -->
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col><div v-bind:style="headerStyleObjectLong2">Last Order Date</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">PLU</div></b-col>
                    <b-col><div v-bind:style="headerStyleObjectLong">Barcode</div></b-col>
                    <b-col><div v-bind:style="headerStyleObjectLong2">Product</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Last Price</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Qty</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Return</div></b-col>
                  </b-row>
                  <!-- body -->
                  <b-row :key='"depart_" + key1' v-for='(depart, key1) in list_data'>
                    <b-col>
                      <b-row>
                        <b-col>
                          <div v-bind:style="level1StyleObject">{{ depart.PLU + '-' + depart.Name1 + (depart.Name2?('-' + depart.Name2):'') }}</div>
                        </b-col>
                      </b-row>
                      <b-row :key='"cate_" + cate_key' v-for='(cate, cate_key) in depart.categorylist'>
                        <b-col>
                          <b-row>
                            <b-col>
                              <div v-bind:style="level2StyleObject">{{ cate.PLU + '-' + cate.Name1 + (cate.Name2?('-' + cate.Name2):'') }}</div>
                            </b-col>
                          </b-row>
                          <b-row :key='"product_" + product_key' v-for='(product, product_key) in cate.products'>
                            <b-col><div v-bind:style="generalStyleObjectLong2">{{ product.OrderDate ? (product.OrderDate.length > 10 ? product.OrderDate.substr(0, 10) : product.OrderDate) : "" }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObject">{{ product.PLU }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObjectLong">{{ product.Barcode }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObjectLong2">{{ product.Name1 + (product.Name2?('-' + product.Name2):'') }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(product.UnitPrice).toFixed(2) }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(product.ShippingQty).toFixed(2) }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObject">{{ product.ReturnQty ? parseFloat(product.ReturnQty).toFixed(2) : '0.00' }}</div></b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row> 
                </div>
              </div>
            </div>
            <div v-else>
              {{ $t('No Records') }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { Table, TableColumn, Button, Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [flatPicker.name]: flatPicker,
    VueElementLoading,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    SearchAutocomplete,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
      },
      titleStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '26px',
      },
      titleStyleObject2: {
        textAlign: 'left',
        'padding-top': '5px',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      titleStyleObject3: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titleStyleObject4: {
        textAlign: 'left',
        fontSize: '18px',
      },
      headerStyleObject: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      generalStyleObject: {
        textAlign: 'right',
        fontSize: '16px',
      },
      headerStyleObjectLong: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
        width: '150px'
      },
      generalStyleObjectLong: {
        textAlign: 'right',
        fontSize: '16px',
        width: '150px'
      },
      headerStyleObjectLong2: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
        width: '200px'
      },
      generalStyleObjectLong2: {
        textAlign: 'right',
        fontSize: '16px',
        width: '200px'
      },
      level1StyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      level2StyleObject: {
        'padding-left': '20px',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      level3StyleObject: {
        'padding-left': '40px',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      leafStyleObject: {
        textAlign: 'right',
        fontSize: '16px',
      },

      token: "",
      user_id: 0,
      export_sheets_for_report: [{ name: "so report", data: [[1,2,3,4],[5,6,7,8]] }],
      export_filename: '',
      export_options: {Sheets: {"so report": {'!cols': [{wch:20}, {wch:30}, {wch:40}, {wch:30}, {wch:10}, {wch:10}]}}},   // not work
      export_url: '',

      list_data: null,
      list_column: null,
      report_width: null,
      title_width: 1000,
      table_width: null,
      showloading: false,

      departmentlist: null,
      categorylist: [],
      storelist: null,
      customer_list: [],
      sales_list: [],
      report_type_list: [{IDn: 1, Name: 'Sales by Category', Title: 'Sales Report', SubTitle: 'By Category'}, 
                         {IDn: 2, Name: 'Sales by Product', Title: 'Sales Report', SubTitle: 'By Product'}, 
                         {IDn: 3, Name: 'Sales by Customer', Title: 'Sales Report', SubTitle: 'By Customer'}, 
                         {IDn: 4, Name: 'Sales by Amount', Title: 'Sales Report', SubTitle: 'By Amount'},
                         {IDn: 5, Name: 'Sales by Customer Product Purchase', Title: 'Sales Report', SubTitle: 'By Customer Product Purchase'}],
      date_type_list: [{IDn: 1, Name: 'Order Date'}, 
                       {IDn: 2, Name: 'Invoice Date'}, 
                       {IDn: 3, Name: 'Delivery Date'}],

      // search conditions
      report_type: 1,
      date_type: 1,
      from_date: null,
      to_date: null,
      cur_department_idn: null,
      cur_category_idn: null,
      cur_store_id: null,
      cur_customer: null,
      cur_sales_idn: null,
      cur_product: null,
      cur_product_name: '',
      summary_only: true,

      saved_store_name: '',
      saved_customer_name: '',
      saved_sales_name: '',
      saved_department_name: '',
      saved_category_name: '',
      saved_datetype_name: '',
      saved_datestr: '',
      saved_product_name: '',
      saved_from_date: '',
      saved_to_date: '',
      saved_report_name: '',
      saved_report_title: '',
      saved_report_subtitle: '',
    };
  },
  watch: {},
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    department_changed() {
      this.cur_category_idn = null;
      if (!this.cur_department_idn) {
        this.categorylist = [];
        return;
      }
      for (var i=0; i<this.departmentlist.length; i++) {
        if (this.cur_department_idn == this.departmentlist[i].IDn) {
          // find it
          this.categorylist = this.departmentlist[i].categorylist;
          break;
        }
      }
    },
    report_type_changed() {
      this.list_data = null;
    },
    set_cur_product(val) {
      this.cur_product = val.key;
      this.cur_product_name = val.value;
    },
    reset_cur_product(val) {
      if (val == '') {
        this.cur_product = '';
        this.cur_product_name = '';
      }
    },
    async get_list_data() {
      this.showloading = true;
      if (this.report_type == 5 && !this.cur_customer) {
        this.showloading = false;
        alert("Customer must be specific");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("report_type", this.report_type);
      bodyFormData.append("date_type", this.date_type);
      
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/SO_report/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&report_type=" + this.report_type;
      url += "&date_type=" + this.date_type;

      if (this.from_date) {
        bodyFormData.append("from_date", this.from_date);
        url += "&from_date=" + this.from_date;
      }
      if (this.to_date) {
        bodyFormData.append("to_date", this.to_date);
        url += "&to_date=" + this.to_date;
      }
      if (this.cur_department_idn) {
        bodyFormData.append("cur_department_idn", this.cur_department_idn);
        url += "&cur_department_idn=" + this.cur_department_idn;
      }
      if (this.cur_category_idn) {
        bodyFormData.append("cur_category_idn", this.cur_category_idn);
        url += "&cur_category_idn=" + this.cur_category_idn;
      }
      if (this.cur_store_id) {
        bodyFormData.append("cur_store_id", this.cur_store_id);
        url += "&cur_store_id=" + this.cur_store_id;
      }
      if (this.cur_customer) {
        bodyFormData.append("cur_customer", this.cur_customer);
        url += "&cur_customer=" + this.cur_customer;
      }
      if (this.cur_sales_idn) {
        bodyFormData.append("cur_sales_idn", this.cur_sales_idn);
        url += "&cur_sales_idn=" + this.cur_sales_idn;
      }
      if (this.cur_product) {
        bodyFormData.append("cur_product", this.cur_product);
        url += "&cur_product=" + this.cur_product;
      }
      bodyFormData.append("summary_only", this.summary_only);
      url += "&summary_only=" + this.summary_only;

      this.save_conditions();
      url += "&report_name=" + this.saved_report_name;
      url += "&report_title=" + this.saved_report_title;
      url += "&report_subtitle=" + this.saved_report_subtitle;
      url += "&saved_store_name=" + this.saved_store_name;
      url += "&saved_customer_name=" + this.saved_customer_name;
      url += "&saved_department_name=" + this.saved_department_name;
      url += "&saved_category_name=" + this.saved_category_name;
      this.export_url = url;
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/SO_report/search",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.list_data = rt.data.list_data;
              that.list_column = rt.data.list_column;
              if (that.report_type == 1 || that.report_type == 2) {
                let width = (rt.data.list_column.length + 1) * 240;
                if (width > that.title_width) {
                  that.report_width = width + 'px';
                } else {
                  that.report_width = that.title_width + 'px';
                }
                that.table_width = width + 'px';
              }
              if (that.report_type == 3) {
                that.report_width = '1200px';
              }
              if (that.report_type == 4) {
                that.report_width = '1100px';
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_init_data();
    },
    async get_init_data() {
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/SO_report",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.storelist;
              that.sales_list = rt.data.sales_list;
              that.departmentlist = rt.data.departmentlist;
              that.customer_list = rt.data.customer_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_for_report(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_report && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_report();
      }
    },
    created_for_report() {
      this.callback_for_report = '';
    },
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    export_for_edit(callback) {
      let datestr = new Date().toLocaleDateString();
      this.export_filename = 'SOReport_' + this.saved_report_name + '_' + this.remove_dash(this.saved_from_date) + '_' + this.remove_dash(this.saved_to_date) + '.xlsx';
      switch (this.report_type) {
        case 1:
          this.export_sheets_for_report[0].data = this.generate_excel_data_by_category();
          break;
        case 2:
          this.export_sheets_for_report[0].data = this.generate_excel_data_by_product();
          break;
        case 3:
          this.export_sheets_for_report[0].data = this.generate_excel_data_by_customer();
          break;
        case 4:
          this.export_sheets_for_report[0].data = this.generate_excel_data_by_amount();
          break;
        case 5:
          this.export_sheets_for_report[0].data = this.generate_excel_data_by_last_order();
          break;
        default:
          break;
      }
      this.callback_for_report = callback;
    },
    save_conditions() {
      this.saved_store_name = 'All Store';
      if (this.cur_store_id) this.saved_store_name = this.storelist.hasOwnProperty(this.cur_store_id) ? this.storelist[this.cur_store_id].LocationName : 'Error Store';
      this.saved_customer_name = 'All Customer';
      if (this.cur_customer) this.saved_customer_name = this.customer_list.hasOwnProperty(this.cur_customer) ? this.customer_list[this.cur_customer].CompanyName : 'Error Customer';
      this.saved_sales_name = 'All Sales';
      if (this.cur_sales_idn) this.saved_sales_name = this.sales_list.hasOwnProperty(this.cur_sales_idn) ? this.sales_list[this.cur_sales_idn].UserName : 'Error Sales';
      this.saved_department_name = 'All Department';
      if (this.cur_department_idn) {
        let find = false;
        for (var i=0; i<this.departmentlist.length; i++) {
          if (this.cur_department_idn == this.departmentlist[i].IDn) {
            // find it
            find = true;
            this.saved_department_name = this.departmentlist[i].Name1;
            break;
          }
        }
        if (!find) this.saved_department_name = 'Error Department';
      }
      this.saved_category_name = 'All Category';
      if (this.cur_category_idn) {
        let find = false;
        for (var i=0; i<this.categorylist.length; i++) {
          if (this.cur_category_idn == this.categorylist[i].IDn) {
            // find it
            find = true;
            this.saved_category_name = this.categorylist[i].Name1;
            break;
          }
        }
        if (!find) this.saved_category_name = 'Error Category';
      }
      this.saved_datetype_name = '';
      switch (this.date_type) {
        case 1:
          this.saved_datetype_name = 'Order Date';
          break;
        case 2:
          this.saved_datetype_name = 'Invoice Date';
          break;
        case 3:
          this.saved_datetype_name = 'Delivery Date';
          break;
        default:
          break;
      }
      this.saved_datestr = new Date().toLocaleDateString();
      this.saved_product_name = this.cur_product_name;
      this.saved_from_date = this.from_date;
      this.saved_to_date = this.to_date;

      this.saved_report_name = this.report_type_list[this.report_type-1].Name;
      this.saved_report_title = this.report_type_list[this.report_type-1].Title;
      this.saved_report_subtitle = this.report_type_list[this.report_type-1].SubTitle;
    },
    generate_conditions() {
      // conditions
      let excel_data = [];
      excel_data.push(['Store:','Customer:','Sales:','Department:','Category:','Product:','DateType:','From:','To:','Print Date:']);
      excel_data.push([this.saved_store_name, this.saved_customer_name, this.saved_sales_name, this.saved_department_name, this.saved_category_name, 
                       this.saved_product_name, this.saved_datetype_name, this.saved_from_date, this.saved_to_date, this.saved_datestr]);
      return excel_data;
    },
    generate_conditions_for_customer() {
      // conditions
      let excel_data = [];
      excel_data.push(['Store:','Customer:','Sales:','DateType:','From:','To:','Print Date:']);
      excel_data.push([this.saved_store_name, this.saved_customer_name, this.saved_sales_name, this.saved_datetype_name, this.saved_from_date, this.saved_to_date, this.saved_datestr]);
      return excel_data;
    },
    generate_conditions_for_lastorder() {
      // conditions
      let excel_data = [];
      excel_data.push(['Store:','Customer:','Department:','Category:','Product:','DateType:','From:','To:','Print Date:']);
      excel_data.push([this.saved_store_name, this.saved_customer_name, this.saved_department_name, this.saved_category_name, 
                       this.saved_product_name, this.saved_datetype_name, this.saved_from_date, this.saved_to_date, this.saved_datestr]);
      return excel_data;
    },
    generate_excel_data_by_category() {
      // conditions
      let excel_data = this.generate_conditions();
      excel_data.push([]);
      
      // data
      let row = ['', '', 'LOCATION'];
      for (var i = 0; i < this.list_column.length; i++) {
        row.push(this.list_column[i].LocationName);
      }
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let depart = this.list_data[i];
        excel_data.push([depart.PLU + '-' + depart.Name1 + (depart.Name2?('-' + depart.Name2):'')]);
        for (var j = 0; j < depart.categorylist.length; j++) {
          let cate = depart.categorylist[j];
          excel_data.push(['', cate.PLU + '-' + cate.Name1 + (cate.Name2?('-' + cate.Name2):'')]);
          let row_qty = ['', '', 'Sold Qty'];
          let row_sales = ['', '', 'Total Sales($)'];
          let row_profit = ['', '', 'Total Profit($)'];
          let row_margin = ['', '', 'Profit Margin(%)'];
          for (var k = 0; k < cate.stores.length; k++) {
            let store = cate.stores[k];
            if (store && store.Qty != undefined) {
              row_qty.push(parseFloat(store.Qty).toFixed(2));
              row_sales.push(parseFloat(store.Sales).toFixed(2));
              row_profit.push(parseFloat(store.Sales - store.Cost).toFixed(2));
              row_margin.push((100 * (store.Sales - store.Cost) / store.Cost).toFixed(2));
            } else {
              row_qty.push('0.00');
              row_sales.push('0.00');
              row_profit.push('0.00');
              row_margin.push('0.00');
            }
          }
          excel_data.push(row_qty);
          excel_data.push(row_sales);
          excel_data.push(row_profit);
          excel_data.push(row_margin);
        }
      }

      row = ['', '', 'Total'];
      for (var i = 0; i < this.list_column.length; i++) {
        row.push(this.list_column[i].Sales);
      }
      excel_data.push(row);

      return excel_data;
    },
    generate_excel_data_by_product() {
      // conditions
      let excel_data = this.generate_conditions();
      excel_data.push([]);
      
      // data
      let row = ['', '', '', 'LOCATION'];
      for (var i = 0; i < this.list_column.length; i++) {
        row.push(this.list_column[i].LocationName);
      }
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let depart = this.list_data[i];
        excel_data.push([depart.PLU + '-' + depart.Name1 + (depart.Name2?('-' + depart.Name2):'')]);
        for (var j = 0; j < depart.categorylist.length; j++) {
          let cate = depart.categorylist[j];
          excel_data.push(['', cate.PLU + '-' + cate.Name1 + (cate.Name2?('-' + cate.Name2):'')]);
          for (var q = 0; q < cate.products.length; q++) {
            let product = cate.products[q];
            excel_data.push(['', '', product.PLU + '-' + product.Name1 + (product.Name2?('-' + product.Name2):'')]);
            let row_qty = ['', '', '', 'Sold Qty'];
            let row_ave_cost = ['', '', '', 'Average Cost($)'];
            let row_sales = ['', '', '', 'Total Sales($)'];
            let row_profit = ['', '', '', 'Total Profit($)'];
            let row_margin = ['', '', '', 'Profit Margin(%)'];
            for (var k = 0; k < product.stores.length; k++) {
              let store = product.stores[k];
              if (store && store.Qty != undefined) {
                row_qty.push(parseFloat(store.Qty).toFixed(2));
                row_ave_cost.push(parseFloat(store.Cost / store.Qty).toFixed(2));
                row_sales.push(parseFloat(store.Sales).toFixed(2));
                row_profit.push(parseFloat(store.Sales - store.Cost).toFixed(2));
                row_margin.push((100 * (store.Sales - store.Cost) / store.Cost).toFixed(2));
              } else {
                row_qty.push('0.00');
                row_ave_cost.push('0.00');
                row_sales.push('0.00');
                row_profit.push('0.00');
                row_margin.push('0.00');
              }
            }
            excel_data.push(row_qty);
            excel_data.push(row_ave_cost);
            excel_data.push(row_sales);
            excel_data.push(row_profit);
            excel_data.push(row_margin);
          }
        }
      }

      row = ['', '', '', 'Total'];
      for (var i = 0; i < this.list_column.length; i++) {
        row.push(this.list_column[i].Sales);
      }
      excel_data.push(row);

      return excel_data;
    },
    generate_excel_data_by_customer() {
      // conditions
      let excel_data = this.generate_conditions_for_customer();
      excel_data.push([]);
      
      // data
      let row = ["Invoice Date","Invoice#","SubTotal","Taxes","Shipping","Other","Total","Payment","Balance","By Sales"];
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let customer = this.list_data[i];
        excel_data.push(['Customer: ' + customer.CompanyName + (customer.OtherName?(' / ' + customer.OtherName):'')]);
        for (var j = 0; j < customer.SOS.length; j++) {
          let so = customer.SOS[j];
          let so_row = [];
          so_row.push(so.SOEndDate ? (so.SOEndDate.length > 10 ? so.SOEndDate.substr(0, 10) : so.SOEndDate) : "");
          so_row.push(so.InvoiceNo);
          so_row.push(parseFloat(so.SubTotal).toFixed(2));
          so_row.push(parseFloat(so.TotalTax).toFixed(2));
          so_row.push(parseFloat(so.ShipFee).toFixed(2));
          so_row.push(parseFloat(so.OtherFees).toFixed(2));
          so_row.push(parseFloat(so.TotalAmount).toFixed(2));
          so_row.push(parseFloat(so.PaidAmount).toFixed(2));
          so_row.push(parseFloat(so.Balance).toFixed(2));
          so_row.push(so.OrderByName);

          excel_data.push(so_row);
        }

        let total_row = [];
        total_row.push('Total:');
        total_row.push('');
        total_row.push(parseFloat(customer.Total.SubTotal).toFixed(2));
        total_row.push(parseFloat(customer.Total.TotalTax).toFixed(2));
        total_row.push(parseFloat(customer.Total.ShipFee).toFixed(2));
        total_row.push(parseFloat(customer.Total.OtherFees).toFixed(2));
        total_row.push(parseFloat(customer.Total.TotalAmount).toFixed(2));
        total_row.push(parseFloat(customer.Total.PaidAmount).toFixed(2));
        total_row.push(parseFloat(customer.Total.Balance).toFixed(2));
        total_row.push('');
        excel_data.push(total_row);
        excel_data.push([]);
      }

      return excel_data;
    },
    generate_excel_data_by_amount() {
      // conditions
      let excel_data = this.generate_conditions_for_customer();
      excel_data.push([]);
      
      // data
      let row = ["Start Date","Customer","SubTotal","Total","Count","Payment","Balance","By Sales"];
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let so = this.list_data[i];
        let so_row = [];
        so_row.push(so.StartDate ? (so.StartDate.length > 10 ? so.StartDate.substr(0, 10) : so.StartDate) : "");
        so_row.push(so.CompanyName + (so.OtherName?(' / ' + so.OtherName):''));
        so_row.push(parseFloat(so.SubTotal).toFixed(2));
        so_row.push(parseFloat(so.TotalAmount).toFixed(2));
        so_row.push(so.Count);
        so_row.push(parseFloat(so.PaidAmount).toFixed(2));
        so_row.push(parseFloat(so.Balance).toFixed(2));
        so_row.push(so.OrderByName);
        excel_data.push(so_row);
      }

      return excel_data;
    },
    generate_excel_data_by_last_order() {
      // conditions
      let excel_data = this.generate_conditions_for_lastorder();
      excel_data.push([]);
      
      // data
      let row = ['', '', 'Last Order Date', 'PLU', 'Barcode', 'Product', 'Last Price', 'Qty', 'Return'];
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let depart = this.list_data[i];
        excel_data.push([depart.PLU + '-' + depart.Name1 + (depart.Name2?('-' + depart.Name2):'')]);
        for (var j = 0; j < depart.categorylist.length; j++) {
          let cate = depart.categorylist[j];
          excel_data.push(['', cate.PLU + '-' + cate.Name1 + (cate.Name2?('-' + cate.Name2):'')]);
          for (var k = 0; k < cate.products.length; k++) {
            let product = cate.products[k];
            row = ['',''];
            row.push(product.OrderDate ? (product.OrderDate.length > 10 ? product.OrderDate.substr(0, 10) : product.OrderDate) : "");
            row.push(product.PLU);
            row.push(product.Barcode);
            row.push(product.Name1 + (product.Name2?('-' + product.Name2):''));
            row.push(parseFloat(product.UnitPrice).toFixed(2));
            row.push(parseFloat(product.ShippingQty).toFixed(2));
            row.push(product.ReturnQty ? parseFloat(product.ReturnQty).toFixed(2) : '0.00');
            excel_data.push(row);
          }
        }
      }
      return excel_data;
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
